<script setup lang="ts"></script>
<template>
  <header>
    <app-header />
  </header>

  <main class="p-8 dark:bg-slate-900 dark:text-white bg-white text-slate-900 min-h-screen">
    <router-view />
  </main>
</template>
