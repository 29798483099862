<script setup lang="ts">

interface Image {
  large: string;
  thumb: string;
  id: number;
  panelTypename?: string;
}
interface Props {
  images: Array<Image>;
}

const props = defineProps<Props>();

defineEmits([]);

const visibleRef: any = ref({});
const imgsRef: any = ref([]);

imgsRef.value = props.images.reduce((arr: Array<string>, current: Image) => {
  arr.push(current.large);
  return arr;
}, []);

const openDirection = ref('');

const openPopup = (id: number, direction: string = "") => {
  visibleRef.value[id] = true;
  openDirection.value = direction;
};

const openPrev = (index: number) => {
  closePopup(props.images[index].id);
  openPopup(props.images[index - 1].id, 'fromLeft');
};

const openNext = (index: number) => {
  closePopup(props.images[index].id);
  openPopup(props.images[index + 1].id, 'fromRight');
};

const closePopup = (id: number) => {
  visibleRef.value[id] = false;
};
const onHide = () => (visibleRef.value = false);
</script>
<template>
  <div class="ml-1 flex justify-start items-center">
    <div v-for="(img, index) in props.images" :key="index" @click="() => openPopup(img.id)"
      class="w-7 h-7 bg-slate-500 overflow-hidden rounded-full -ml-1 -my-1 cursor-pointer">
      <ImagePopup v-if="visibleRef[img.id]" :imgid="img.id" :imgurl="img.large" @close="closePopup(img.id)"
        :hasPrev="index != 0" :hasNext="index < Object.keys(props.images).length - 1" :openDirection="openDirection"
        @prev="openPrev(index)" @next="openNext(index)" :panelTypename="img.panelTypename"></ImagePopup>
      <img :src="img.thumb" alt="" class="block h-full w-full border-none" />
    </div>
  </div>
</template>
