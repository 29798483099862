<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { shallowRef } from 'vue';
import { useRoute } from 'vue-router';
import { usePagesStore } from '@/store';

import IconLogout from '~icons/ion/power-outline';
import IconArrow from '~icons/clarity/angle-line';
//import IconMenu from '~icons/circum/circle-more';
import IconMenu from '~icons/ph/dots-nine-bold';

const pagesStore = usePagesStore();
const route = useRoute();

const usernavItems = reactive([
  {
    title: 'Logout',
    route: '/logout',
    icon: shallowRef(IconLogout),
  },
]);
</script>
<template>
  <div class="py-5 mx-6 md:mx-12 border-b border-b-slate-200 flex justify-between">
    <div class="flex-grow">
      <h1 class="text-xl font-medium text-slate-900 leading-7 pb-1 pt-2 flex items-center">
        <a v-if="route.meta.backButton" @click="$router.go(-1)"
          class="text-slate-900 hover:text-slate-700 inline-flex items-center mr-2 cursor-pointer">
          <IconArrow class="inline-block w-6 h-6 -rotate-90" />
        </a>
        {{ pagesStore.currentPage }}
      </h1>
    </div>
    <Menu as="div" class="w-10 h-10 -mr-1 relative z-50">
      <MenuButton as="a"
        class="flex w-10 h-10 mt-[0.125rem] justify-center items-center cursor-pointer rounded-full aria-expanded:bg-slate-200 text-l font-bold">
        <IconMenu class="w-7 h-7 text-slate-900" />
      </MenuButton>
      <PopoutMenu :items="usernavItems"></PopoutMenu>
    </Menu>
  </div>
</template>
