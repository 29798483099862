<script setup lang="ts">
  import { MenuItems, MenuItem } from '@headlessui/vue';

  interface LinkElements {
    items: Array<{
      title: string;
      route: string;
      icon: any;
    }>;
  }

  const props = defineProps<LinkElements>();
</script>
<template>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-from-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-from-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <MenuItems
      class="absolute right-0 min-w-[11rem] origin-top-right bg-white divide-y divide-slate-200 rounded-md overflow-hidden shadow-centered focus:outline-none z-10"
    >
      <MenuItem v-for="(item, index) in props.items" :key="index" v-slot="{ active }">
        <router-link
          as="button"
          :to="item.route"
          :class="[active ? 'bg-slate-50' : 'text-slate-900', 'group flex items-center w-full px-4 py-3 text-sm']"
        >
          <component :is="item.icon" :active="active" class="w-5 h-5 mr-3 text-slate-900" aria-hidden="true" />
          {{ item.title }}
        </router-link>
      </MenuItem>
    </MenuItems>
  </transition>
</template>
