<script setup lang="ts">
  import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from '@headlessui/vue';
  import { Ref } from 'vue';
  import CheckIcon from '~icons/bx/check';
  import SelectorIcon from '~icons/gg/select';

  interface SelectElements {
    items: { [key: string]: string };
    modelValue?: string;
  }

  const props = defineProps<SelectElements>();
  const emit = defineEmits(['update:modelValue', 'updateQuery']);

  const selectedItem: Ref<string> = ref(props.modelValue ?? '');

  watch(
    () => props.modelValue,
    () => {
      selectedItem.value = props.modelValue ?? '';
    }
  );

  watch(
    selectedItem,
    (newVal, _oldVal) => {
      emit('update:modelValue', newVal);
    },
    { immediate: true }
  );

  let query = ref('');

  watch(
    query,
    (newVal, _oldVal) => {
      emit('updateQuery', newVal);
    },
    { immediate: false }
  );
</script>
<template>
  <div class="w-full">
    <Combobox v-model="selectedItem">
      <div class="relative">
        <ComboboxInput
          class="bg-transparent focus:bg-white border-none px-4 h-[2.375rem] box-border py-1 leading-7 text-sm text-slate-700 outline-hidden focus-within:ring-slate-600 rounded w-full"
          @change="query = $event.target.value"
          :displayValue="(): any => props.items[selectedItem] ?? query"
        >
          <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none outline-hidden border-none">
            <SelectorIcon class="w-5 h-5 text-slate-400" aria-hidden="true" />
          </ComboboxButton>
        </ComboboxInput>
        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ComboboxOptions
            class="absolute w-full bg-white rounded-md py-2 shadow-centered sm:text-sm focus:outline-hidden z-50 max-h-64 overflow-auto mt-[1px] -ml-[1px]"
          >
            <ComboboxOption v-slot="{ selected, active }" v-for="(item, key) in props.items" :key="key" :value="key" as="template">
              <li :class="[active ? 'bg-slate-50' : '', 'text-slate-900 cursor-default select-none relative py-2 pl-10 pr-4']">
                <div class="hidden">{{ item }}</div>
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate leading-5 h-5']">{{ item }}</span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-slate-600">
                  <CheckIcon class="w-5 h-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </transition>
      </div>
    </Combobox>
  </div>
</template>
<style scoped>
  ul {
    width: calc(100% + 2px);
  }
</style>
