<script setup lang="ts">
import IconCancel from '~icons/ph/x';
import IconSearch from '~icons/system-uicons/capture';
import IconDocument from '~icons/humbleicons/document';
import IconReload from '~icons/tabler/reload';
import IconLoading from '~icons/eos-icons/loading';
import axios from 'axios';
import IconLeft from '~icons/formkit/left';
import IconRight from '~icons/formkit/right';
import qs from 'qs';

const apiBaseUrl = import.meta.env.VITE_APIURL;

interface ImageProps {
  imgurl?: string;
  imgid: number;
  openDirection?: string;
  hasPrev?: boolean;
  hasNext?: boolean;
  panelTypename?: string;
}

const props = defineProps<ImageProps>();

const emit = defineEmits(['close', 'prev', 'next']);

const loadingRefresh = ref(false);
const errorMsg = ref('');
const errorOpen = ref(false);
const res: any = ref(null);
const zoomClicked = ref(false);

const openDirection = computed(() => {
  if (props.openDirection && ['fromLeft', 'fromRight', 'fromBottom'].includes(props.openDirection)) {
    return props.openDirection;
  }
  return 'fromBottom';
});

const closeError = () => {
  errorOpen.value = false;
};

// parse json
const resultObject: any = computed(() => {
  if (res.value !== null) {
    return res.value.data.data.attributes.recognition_result;
  }
  return null;
});

const getScalingFactor = () => {
  if (!resultObject?.value?.label) {
    return 1;
  }

  const scale_x = resultObject.value.label.image_dimensions.width / (resultObject.value.label.position.right - resultObject.value.label.position.left);
  const scale_y = resultObject.value.label.image_dimensions.height / (resultObject.value.label.position.bottom - resultObject.value.label.position.top);

  const scale = Math.min(scale_x, scale_y);

  return scale * 0.9;
}

const getTransformation = () => {
  if (!resultObject?.value?.label) {
    return '';
  }

  const scale = getScalingFactor();

  // translation: ratio of middle of image to middle of label
  const translate_x = (resultObject.value.label.image_dimensions.width / 2 - (resultObject.value.label.position.left + (resultObject.value.label.position.right - resultObject.value.label.position.left) / 2)) / resultObject.value.label.image_dimensions.width * 100 * scale;
  const translate_y = (resultObject.value.label.image_dimensions.height / 2 - (resultObject.value.label.position.top + (resultObject.value.label.position.bottom - resultObject.value.label.position.top) / 2)) / resultObject.value.label.image_dimensions.height * 100 * scale;

  return {
    transform: `translate(${translate_x}%, ${translate_y}%) scale(${scale})`,
  };
}

const closePopup = () => {
  emit('close');
};

const loadRecognitionResult = async () => {
  const requestParams = {
    params: {
      populate: {
        is_basis_for: true,
      },
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params);
    },
  };


  try {
    res.value = await axios.get(apiBaseUrl + 'recognition-images/' + props.imgid);
  } catch (error: any) {
    errorMsg.value = error;
    errorOpen.value = true;
  }
};

onMounted(async () => {
  await loadRecognitionResult();
});


</script>
<template>
  <Teleport to="body">
    <div>
      <Transition appear leave-from-class="opacity-100" leave-to-class="opacity-0"
        :enter-from-class="openDirection == 'fromBottom' ? 'opacity-0' : 'opacity-1'" enter-to-class="opacity-100"
        leave-active-class="transition duration-500 ease-in" enter-active-class="transition duration-500 ease-out">
        <div class="fixed inset-0 z-40">
          <div class="absolute inset-0 z-20 bg-black bg-opacity-50" @click="closePopup()"></div>
          <a v-if="props.hasPrev"
            class="fixed z-30 left-0 inset-y-0 h-24 w-24 my-auto ml-2 rounded-full hover:bg-black hover:bg-opacity-25 cursor-pointer"
            @click="emit('prev')">
            <IconLeft class="w-20 h-20 text-white ml-1 mr-3 my-2" aria-hidden="true" />
          </a>
          <a v-if="props.hasNext"
            class="fixed z-30 right-0 inset-y-0 h-24 w-24 my-auto mr-2 rounded-full hover:bg-black hover:bg-opacity-25 cursor-pointer"
            @click="emit('next')">
            <IconRight class="w-20 h-20 text-white my-2 ml-3 mr-1" aria-hidden="true" />
          </a>
        </div>
      </Transition>
      <Transition appear leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-4"
        :enter-from-class="openDirection == 'fromLeft' ? 'opacity-0 -translate-x-4' :
          openDirection == 'fromRight' ? 'opacity-0 translate-x-4' :
            'opacity-0 translate-y-4'" enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition duration-500 ease-in" enter-active-class="transition duration-500 ease-out">
        <div class="fixed my-8 mx-auto max-w-7xl inset-0 z-50 pointer-events-none">
          <div class="absolute inset-y-0 bg-white mx-8 rounded overflow-y-auto pointer-events-auto w-full">
            <a class="absolute right-0 top-0 m-8 z-10 cursor-pointer" @click.prevent="closePopup()">
              <IconCancel class="w-16 h-16 text-white drop-shadow-2xl" aria-hidden="true" />
            </a>
            <div class="w-full overflow-hidden relative">
              <img :src="props.imgurl"
                class="w-full block scale-100 transition-transform duration-300 translate-x-0 translate-y-0 bg-slate-700"
                :style="zoomClicked ? getTransformation() : ''" />
              <!-- transform image after click -->
              <a v-if="res?.data?.data?.attributes?.recognition_status == 'success'" href=""
                class="absolute block right-0 bottom-0 m-8 group cursor-pointer"
                @click.prevent="zoomClicked = !zoomClicked">
                <IconSearch
                  class="w-16 h-16 text-white drop-shadow-2xl scale-100 group-hover:scale-95 transition-transform duration-150"
                  aria-hidden="true" />
                <IconDocument class="w-8 h-8 text-white drop-shadow-2xl absolute left-4 top-4" aria-hidden="true" />
              </a>
              <div v-if="props.panelTypename"
                class="absolute top-0 left-0 m-8 rounded h-8 leading-6 px-3 py-1 text-center font-normal overflow-hidden bg-white cursor-default hover:opacity-10">
                {{ props.panelTypename }}
              </div>
            </div>
            <div v-if="res !== null" class="p-8">
              <div class="mb-10">
                <div class="flex flex-wrap items-center justify-between content-start gap-x-6 gap-y-3 mt-1">
                  <div class="shrink-0 basis-52 py-2">
                    <StateItem :state="res.data.data.attributes.recognition_status"></StateItem>
                  </div>
                </div>
              </div>
              <div class="mb-10 last:mb-0">
                <h3 class="text-base leading-6 font-medium text-slate-900 relative">Result</h3>
                <pre class="input-text mb-8">{{ res?.data?.data?.attributes?.recognition_result }}</pre>
              </div>
              <div class="flex flex-wrap justify-start items-start content-start gap-x-6 gap-y-3 mb-10 last:mb-0">
                <div class="grow shrink-0 basis-52">
                  <h3 class="text-base leading-6 font-medium text-slate-900 relative">Recognition ID</h3>
                  <pre class="input-text">{{ res?.data?.data?.attributes?.recognition_task_id }}</pre>
                </div>
                <div class="shrink-0 basis-52">
                  <h3 class="text-base leading-6 font-medium text-slate-900 relative">Image ID:</h3>
                  <pre class="input-text">{{ res?.data?.data?.id }}</pre>
                </div>
              </div>
              <div class="mb-10 last:mb-0">
                <h3 class="text-base leading-6 font-medium text-slate-900 relative">Date</h3>
                <div class="flex flex-wrap justify-start items-start content-start gap-x-6 gap-y-3 mt-1">
                  <div class="grow shrink-0 basis-52">
                    <label class="label">Created</label>
                    <input type="text" class="input-text" :value="res?.data?.data?.attributes?.createdAt" disabled>
                  </div>
                  <div class="grow shrink-0 basis-52">
                    <label class="label">Updated</label>
                    <input type="text" class="input-text" :value="res?.data?.data?.attributes?.updatedAt" disabled>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <DialogPopup :open="errorOpen" @close="closeError()" :appearance="'error'" :title="'Error'">
      {{ errorMsg }}
    </DialogPopup>
  </Teleport>
</template>