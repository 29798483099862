import TableImages from '@/components/common/table/TableImages.vue';
import { defineComponent } from 'vue';

const apiServerUrl = import.meta.env.VITE_APISERVERURL;

export default class TableImagesService {
  public static customImages = (row: any, field: string) => {
    if (row?.attributes?.recognition_images?.data === undefined) {
      return;
    }
    let arr = row.attributes.recognition_images.data;
    let typename = row?.attributes?.panelmodel?.data?.attributes?.typename;
    let urls: Array<any> = arr.reduce((result: Array<any>, current: any) => {
      if (current.attributes.img.data !== null) {
        let thumb = apiServerUrl + current?.attributes?.img?.data?.attributes?.formats?.thumbnail?.url;
        let large = apiServerUrl + current?.attributes?.img?.data?.attributes?.url;
        let img = {
          thumb: thumb,
          large: large,
          id: current?.id,
          panelTypename: typename,
        };
        if (thumb !== undefined && large !== undefined) {
          result.push(img);
        }
        return result;
      } else {
        return result;
      }
    }, new Array<any>());

    // @ts-ignore
    const instance = defineComponent(TableImages);
    return [instance, { images: urls }];
  };
}
