import TableTypenameSelect from '@/components/common/table/TableTypenameSelect.vue';
import axios from 'axios';
import { defineComponent } from 'vue';
import { computed, ref } from 'vue';

const apiBaseUrl = import.meta.env.VITE_APIURL;

export default class TableModelService {
  private static instance: TableModelService;
  private static refModelValues: any = ref([]);
  private static panelList: any = ref([]);

  private constructor() {}

  static getInstance(): TableModelService {
    if (!TableModelService.instance) {
      TableModelService.instance = new TableModelService();
    }

    return TableModelService.instance;
  }

  private static comPanelModels = computed(() => {
    if (!TableModelService.panelList.value) {
      return [];
    }
    return TableModelService.panelList.value
      .map((item: any) => {
        return {
          id: item.id,
          manufacturer: item.attributes.manufacturer,
          typename: item.attributes.typename,
        };
      })
      .reduce((result: any, current: any) => {
        result[current.id] = current.typename + ', ' + current.manufacturer;
        return result;
      }, new Object());
  });

  public static customModel = (row: any, field: string, refreshTable: Function) => {
    let typename: string = row?.attributes?.panelmodel?.data?.attributes?.typename;
    let manufacturer: string = row?.attributes?.panelmodel?.data?.attributes?.manufacturer;
    if (typename && manufacturer) {
      return '<span class="font-semibold">' + typename + '</span> <span class="text-slate-500 text-xs">' + manufacturer + '</span>';
    } else {
      // @ts-ignore
      const instance = defineComponent(TableTypenameSelect);
      return [
        instance,
        {
          items: TableModelService.comPanelModels.value,
          modelValue: null,
        },
        {
          'update:modelValue': (val: any) => {
            TableModelService.refModelValues.value[row.id] = val;
          },
          updateQuery: (val: any) => {
            TableModelService.filterPanelList(val);
          },
          save: () => {
            axios
              .put(apiBaseUrl + 'solarpanels/' + row.id, {
                data: {
                  panelmodel: TableModelService.refModelValues.value[row.id],
                  recognition_status: 'manuallyRecognized',
                },
              })
              .then(() => {
                refreshTable();
              })
              .catch(error => {
                console.log('error', error);
              });
          },
        },
      ];
    }
  };

  public static filterPanelList = (searchString: string) => {
    if (searchString.length > 0) {
      const searchType = searchString.split(',')[0].trim();

      axios
        .get(
          apiBaseUrl +
            'panelmodels/?filters[$or][0][typename][$contains]=' +
            searchString +
            '&filters[$or][1][typename][$contains]=' +
            searchType +
            '&filters[$or][2][manufacturer][$contains]=' +
            searchString
        )
        .then(response => {
          TableModelService.panelList.value = response.data.data;
        });
    } else {
      axios.get(apiBaseUrl + 'panelmodels/').then(response => {
        TableModelService.panelList.value = response.data.data;
      });
    }
  };
}
