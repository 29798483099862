<script setup lang="ts">
import IconArrow from '~icons/clarity/angle-line';
import { useSocketStore } from '@/store';
import { onMounted, onBeforeMount, onUnmounted } from 'vue';
import io from 'socket.io-client';
import axios from 'axios';
import qs from 'qs';
import RecognitionStateFormatter from '@/services/RecognitionStateFormatter';
import { Ref } from 'vue';

const props = defineProps<{
  timerValue?: number;
}>();

const emit = defineEmits(['dispatchedEntry', 'newEntry', 'recognitionChange']);

const apiBaseUrl = import.meta.env.VITE_APIURL;
const apiServerUrl = import.meta.env.VITE_APISERVERURL;

// get socket from pinia store or create it if not existing
const socketStore: any = useSocketStore();
let socket: any = null;

onBeforeMount(() => {
  socket = (() => {
    if (!socketStore.socket) {
      const socket = io(apiServerUrl, {
        auth: {
          token: sessionStorage?.getItem('jwt'),
        },
      });
      socketStore.$patch({
        socket: socket,
      });
      return socket;
    } else {
      return socketStore.socket.connect();
    }
  })();
});

let panels: any = ref({});
let images: any = ref({});
let expandPanels: any = ref(true);

interface ImageList {
  [key: number]: any;
}

const addToImageList = (data: any, type: string) => {
  let arr;

  if (type == 'socket') {
    arr = data.recognition_images;
  } else {
    arr = data.attributes.recognition_images.data;
  }

  let imgs: {} = arr.reduce((result: ImageList, current: any) => {
    switch (type) {
      case 'socket':
        if (current?.img) {
          let small_img_socket = apiServerUrl + current?.img?.formats?.small?.url;
          let large_img_socket = apiServerUrl + current?.img?.url;
          let isTypenameSource = current?.is_basis_for ? true : false;

          result[current?.id] = {
            small: small_img_socket,
            large: large_img_socket,
            status: current.recognition_status,
            isTypenameSource: isTypenameSource,
          };
        }
        break;
      case 'http':
        if (current?.attributes?.img?.data) {
          let small_img_http = apiServerUrl + current?.attributes?.img?.data?.attributes?.formats?.small?.url;
          let large_img_http = apiServerUrl + current?.attributes?.img?.data?.attributes?.url;
          let isTypenameSource = current?.attributes?.is_basis_for?.data ? true : false;

          result[current?.id] = {
            small: small_img_http,
            large: large_img_http,
            status: current?.attributes?.recognition_status,
            isTypenameSource: isTypenameSource,
          };
        }
        break;
    }
    return result;
  }, reactive({}));

  images.value = { ...images.value, ...imgs };
};

// Get all panels with recognitionStatus "processing" and store them in the panels variable
const getPanels = async () => {
  // Create axios configuration to select only processing panels and populate the images
  const requestParams = {
    params: {
      populate: {
        recognition_images: {
          populate: {
            img: {
              populate: {
                media: {
                  populate: true,
                },
              },
            },
            is_basis_for: true,
          },
        },
        panelmodel: {
          populate: true,
        },
      },
      filters: {
        recognition_status: {
          $eq: 'processing',
        },
      },
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params);
    },
  };

  let response = await axios.get(apiBaseUrl + 'solarpanels', requestParams);
  if (response.status === 200) {
    for (let i = 0; i < response.data.data.length; i++) {
      let imageIds = null;
      if (response?.data?.data[i]?.attributes?.recognition_images?.data?.length > 0) {
        imageIds = response?.data?.data[i]?.attributes?.recognition_images?.data.map((img: any) => img.id);
      }
      panels.value[response.data.data[i].id] = {
        recognitionKey: 'processing',
        recognitionColor: RecognitionStateFormatter.getColor('processing'),
        recognitionState: RecognitionStateFormatter.getLabel('processing'),
        manufacturer: response.data.data[i].attributes?.panelmodel?.data?.attributes?.manufacturer,
        model: response.data.data[i].attributes?.panelmodel?.data?.attributes?.typename,
        modelId: response.data.data[i].attributes?.panelmodel?.data?.id,
        imageIds: imageIds,
      };
      addToImageList(response.data.data[i], 'http');
    }
  }
};

await getPanels();

onMounted(() => {
  socket.on('solarpanel:newSolarPanel', (data: any) => {
    newSolarPanel(data);
  });
  socket.on(
    'solarpanel:recognitionChange',
    (data: { id: any; recognition_status: string; manufacturer: any; typename: any; modelId: any, model_based_on: any }) => {
      recognitionChange(data.id, data.recognition_status, data.manufacturer, data.typename, data.modelId, data.model_based_on);
    }
  );
  socket.on('recognition-image:recognitionChange', (data: { id: number; recognition_status: string }) => {
    imageRecognitionChange(data.id, data.recognition_status);
  });
});

onUnmounted(() => {
  socket.off('solarpanel:newSolarPanel');
  socket.off('solarpanel:recognitionChange');
  socket.off('recognition-image:recognition-change');
});

const newSolarPanel = (data: any) => {
  emit('newEntry');

  let id = data?.id;
  let recognitionState = data?.recognition_status;

  panels.value[id] = {
    recognitionKey: panels.value[id]?.recognitionKey ?? recognitionState,
    recognitionColor: panels.value[id]?.recognitionColor ?? RecognitionStateFormatter.getColor(recognitionState),
    recognitionState: panels.value[id]?.recognitionState ?? RecognitionStateFormatter.getLabel(recognitionState),
    manufacturer: panels.value[id]?.manufacturer ?? '',
    model: panels.value[id]?.model ?? '',
    modelId: panels.value[id]?.modelId ?? '',
    imageIds: data?.recognition_images?.map((image: any) => {
      return image.id;
    }, new Array<number>()),
  };
  addToImageList(data, 'socket');
};

const recognitionChange = (id: any, recognition_state: string, manufacturer: any, typename: any, modelId: any, model_based_on: any) => {
  if (!panels.value[id]) {
    return;
  }
  panels.value[id].recognitionKey = recognition_state;
  panels.value[id].recognitionState = RecognitionStateFormatter.getLabel(recognition_state);
  panels.value[id].recognitionColor = RecognitionStateFormatter.getColor(recognition_state);
  panels.value[id].manufacturer = manufacturer;
  panels.value[id].model = typename;
  panels.value[id].modelId = modelId;

  if (recognition_state == 'recognitionFailed' || recognition_state == 'manuallyRecognized' || recognition_state == 'autoRecognized' || recognition_state == 'confirmed') {
    emit('recognitionChange');

    if (!panels.value[id]) {
      return;
    }

    if (recognition_state == 'autoRecognized') {
      triggerShowOptions(id, true);
    }
    if (recognition_state == 'manuallyRecognized' || recognition_state == 'confirmed') {
      triggerShowParameters(id);
      triggerShowOptions(id, false);
    }

    if (model_based_on) {
      setToTypenameSource(model_based_on.id);
    }
  }
};

const triggerShowOptions = (id: number, val: boolean) => {
  panels.value[id].openOptions = val;
}

const triggerShowParameters = (id: number) => {
  // request parameters using axios
  const requestParams = {
    params: {
      populate: 'process_parameter',
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params);
    },
  };

  axios
    .get(apiBaseUrl + 'panelmodels/' + panels.value[id].modelId, requestParams)
    .then(response => {
      if (response.status === 200) {
        panels.value[id].parameters = response?.data?.data?.attributes?.process_parameter?.data?.attributes;
      }
    })
    .catch(error => {
      console.log(error);
    });
};

const imageRecognitionChange = (img_id: any, status: any) => {
  if (!images.value[img_id]) {
    return;
  }
  images.value[img_id].status = status;
};

const setToTypenameSource = (img_id: any) => {
  if (!images.value[img_id]) {
    return;
  }
  images.value[img_id].isTypenameSource = true;
};

const panelsImages = computed(() => {
  // For every panel, store the according images in an array
  let list: any = {};
  for (const panelid in panels.value) {
    let imglist: any = {};
    if (panels.value[panelid]?.imageIds?.length > 0) {
      for (const img of panels.value[panelid]?.imageIds) {
        if (images.value[img]) {
          imglist[img] = images.value[img];
        }
      }
    }
    if (Object.keys(imglist).length > 0) {
      list[panelid] = imglist;
    } else {
      list[panelid] = {};
    }
  }
  return list;
});

// set recognitionState of panel to "failed"
const abortRecognition = async (panelId: number) => {
  if (panels.value[panelId].recognitionState == 'recognitionFailed') {
    removePanel(panelId);
  } else {
    const requestParams = {
      data: {
        recognition_status: 'recognitionFailed',
      },
    };
    await axios.put(apiBaseUrl + 'solarpanels/' + panelId, requestParams);
    if (panels.value[panelId]) {
      removePanel(panelId);
    }
  }
};

const removePanel = (id: number) => {
  delete panels.value[id];
  emit('dispatchedEntry');
};

const tooltiptext = computed(() => {
  if (!expandPanels) {
    return 'Collapse panel list';
  } else {
    return 'Expand panel list';
  }
});
</script>
<template>
  <div class="relative pb-2 mb-10 min-h-[21rem] z-20">
    <div>
      <h3
        class="mb-3 text-lg leading-8 inline-flex items-center justify-center font-medium text-slate-900 cursor-pointer"
        @click="expandPanels = !expandPanels">
        <div v-if="Object.keys(panels).length > 1" class="h-8 -ml-1 mr-1">
          <Tooltip :content="tooltiptext">
            <div class="transition-transform text-slate-900 w-8 h-8 inline-flex items-center justify-center"
              :class="{ 'rotate-180': expandPanels, 'rotate-90': !expandPanels }">
              <IconArrow class="w-6 h-6"></IconArrow>
            </div>
          </Tooltip>
        </div>
        Currently processing:
      </h3>
      <Transition name="slidein">
        <h4 v-if="Object.keys(panels).length == 0"
          class="mb-2 text-base font-normal text-slate-600 absolute text-center py-20 rounded border-dashed border-2 border-slate-200 w-full">
          No panels are being processed currently.
        </h4>
      </Transition>
    </div>

    <div>
      <TransitionGroup name="slidein">
        <LiveWatchPanel v-for="(id, index) in Object.keys(panels)
          .map(x => parseInt(x))
          .sort((a, b) => a - b)
          .reverse()" :key="id"
          class="scale-100 transition-all origin-bottom duration-[0.15s] w-full mb-2 bottom-0 first:z-30 focus-within:z-40"
          :class="{
          'absolute transition-all scale-95 origin-bottom -bottom-3 z-20': !expandPanels && index == 1,
          'absolute transition-all scale-90 origin-bottom -bottom-6 z-10': !expandPanels && index == 2,
          'absolute transition-all scale-90 origin-bottom -bottom-6 z-0 opacity-0': !expandPanels && index > 2,
          'transition-all relative opacity-100 mb-2 z-30': expandPanels || index == 0,
        }" :id="id" :panel="panels[id]" :images="panelsImages[id]" @abortRecognition="abortRecognition(id)"
          @hidePanel="removePanel(id)" :parameters="panels[id].parameters">
        </LiveWatchPanel>
      </TransitionGroup>
    </div>
  </div>
</template>
