

import __layout_0 from '/home/ubuntu/adminui/src/layouts/default.vue'
export const layouts = {
'404': () => import('/home/ubuntu/adminui/src/layouts/404.vue'),
'backend': () => import('/home/ubuntu/adminui/src/layouts/backend.vue'),
'default': __layout_0,
'login': () => import('/home/ubuntu/adminui/src/layouts/login.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
